<template>
  <div class="personal-result-view">
    <div class="result-main">
      <div class="main-top">
        <result-footer :list="levelData" :obj="{ name: name, date: date }" model="A" />
      </div>
      <tojoy-pagination-list
        :data="list"
        :total="total"
        :size="size"
        @page-change="handleChangePage"
        :loading="loading"
        :show-radius="false"
      >
        <template #item="{data}">
          <tojoy-card :data="data" schema="people">
            <template #downcard>
              <organization-item-card :list="data" />
            </template>
          </tojoy-card>
        </template>
      </tojoy-pagination-list>
    </div>
  </div>
</template>

<script>
import organizationItemCard from '@/components/organizational/organization-item-card.vue'
import { getEvalResult } from '@/api/api_organization'
import { resultMixin } from '@/mixins/organize/resultMixin.js'
export default {
  name: 'organizational-result',
  mixins: [resultMixin],
  components: { organizationItemCard },
  methods: {
    /**
     * 分页
     */
    handleChangePage(val) {
      this.page = val
      this.loading = true
      this.getList()
    },
    /**
     *列表数据
     */
    getList() {
      let params = {
        eocId: this.orgId
      }
      this.handleApi(params, getEvalResult)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/personalResult.scss';
/deep/.item-card {
  padding-right: 30px;
}
</style>
