<template>
  <div class="organization-item">
    <div class="box-left">
      <div class="title">{{ list.pocTitle }}</div>
      <div class="content">
        <span>组织绩效总分</span>
        <span class="bold">{{ list.orgSumScore || 0 }}分</span>
      </div>
      <div class="grade">
        <span>组织负责人</span>
        <span class="bold">{{ list.partakeLeaderName }}</span>
        <template v-if="typeof list.sumScore === 'number'">
          <span class="sum">绩效综合得分</span>
          <span class="bold">{{ list.sumScore || 0 }}分</span>
        </template>
        <span class="level" :class="list.partakePfLevel">{{ list.partakePfLevel }}</span>
      </div>
    </div>
    <div class="box-right" :class="list.pfLevel">{{ list.pfLevel }}</div>
  </div>
</template>

<script>
export default {
  name: 'organization-item-card',
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.organization-item {
  display: flex;
  flex: 1;
  padding-bottom: 20px;
  .box-left {
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
      line-height: 16px;
      margin-bottom: 15px;
    }
    .content {
      font-size: 12px;
      font-weight: 400;
      color: $grey;
      line-height: 12px;
      margin-bottom: 15px;
    }
    .bold {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
    }
    .grade {
      font-size: 12px;

      font-weight: 400;
      color: $grey;
      line-height: 12px;
      .sum {
        margin-left: 10px;
      }
      .level {
        margin-left: 10px;
      }
    }
  }
  .box-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .C {
    color: $red;
  }
  .S {
    color: $C89557;
  }
  .A {
    color: $blue;
  }
}
</style>
